import React from 'react';
import Reveal from '../../../components/Reveal';
import {graphql,useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';
//	http://www.landscapedepotsupply.com/Natural_Wallstone.htm

export default function() {

	const {
		imageMobile_bluestone_tumbled,			imageDesktop_bluestone_tumbled,
		imageMobile_granite_american,			imageDesktop_granite_american,
		imageMobile_granite_assorted,			imageDesktop_granite_assorted,
		imageMobile_granite_saratoga,			imageDesktop_granite_saratoga,
		imageMobile_granite_summit,				imageDesktop_granite_summit,
		imageMobile_granite_van_tassell,		imageDesktop_granite_van_tassell,
		imageMobile_new_england_fieldstone_01,	imageDesktop_new_england_fieldstone_01,
		imageMobile_new_england_fieldstone_02,	imageDesktop_new_england_fieldstone_02,
		imageMobile_new_england_flats,			imageDesktop_new_england_flats,
		imageMobile_pennsylvania,				imageDesktop_pennsylvania,
		imageMobile_quartzite_south_bay,		imageDesktop_quartzite_south_bay,
		imageMobile_ticonderoga,				imageDesktop_ticonderoga,
	} = useStaticQuery(
		graphql`
		  query {

			imageMobile_bluestone_tumbled: file(relativePath: { eq: "pages/index/StoneTypesGrid/bluestone_tumbled.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_bluestone_tumbled: file(relativePath: { eq: "pages/index/StoneTypesGrid/bluestone_tumbled.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_granite_american: file(relativePath: { eq: "pages/index/StoneTypesGrid/granite_american.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_granite_american: file(relativePath: { eq: "pages/index/StoneTypesGrid/granite_american.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_granite_assorted: file(relativePath: { eq: "pages/index/StoneTypesGrid/granite_assorted.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_granite_assorted: file(relativePath: { eq: "pages/index/StoneTypesGrid/granite_assorted.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_granite_saratoga: file(relativePath: { eq: "pages/index/StoneTypesGrid/granite_saratoga.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_granite_saratoga: file(relativePath: { eq: "pages/index/StoneTypesGrid/granite_saratoga.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_granite_summit: file(relativePath: { eq: "pages/index/StoneTypesGrid/granite_summit.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_granite_summit: file(relativePath: { eq: "pages/index/StoneTypesGrid/granite_summit.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_granite_van_tassell: file(relativePath: { eq: "pages/index/StoneTypesGrid/granite_van_tassell.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_granite_van_tassell: file(relativePath: { eq: "pages/index/StoneTypesGrid/granite_van_tassell.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_new_england_fieldstone_01: file(relativePath: { eq: "pages/index/StoneTypesGrid/new_england_fieldstone.01.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_new_england_fieldstone_01: file(relativePath: { eq: "pages/index/StoneTypesGrid/new_england_fieldstone.01.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_new_england_fieldstone_02: file(relativePath: { eq: "pages/index/StoneTypesGrid/new_england_fieldstone.02.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_new_england_fieldstone_02: file(relativePath: { eq: "pages/index/StoneTypesGrid/new_england_fieldstone.02.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_new_england_flats: file(relativePath: { eq: "pages/index/StoneTypesGrid/new_england_flats.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_new_england_flats: file(relativePath: { eq: "pages/index/StoneTypesGrid/new_england_flats.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_pennsylvania: file(relativePath: { eq: "pages/index/StoneTypesGrid/pennsylvania.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_pennsylvania: file(relativePath: { eq: "pages/index/StoneTypesGrid/pennsylvania.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_quartzite_south_bay: file(relativePath: { eq: "pages/index/StoneTypesGrid/quartzite_south_bay.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_quartzite_south_bay: file(relativePath: { eq: "pages/index/StoneTypesGrid/quartzite_south_bay.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_ticonderoga: file(relativePath: { eq: "pages/index/StoneTypesGrid/ticonderoga.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_ticonderoga: file(relativePath: { eq: "pages/index/StoneTypesGrid/ticonderoga.jpg" }) {
			  childImageSharp {
				fluid(quality:90, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

		  }
		`
	  );

  return (
		<div style={{

//			background: 'rgb(180,180,180)',
//			background:	'linear-gradient(180deg, rgba(180,180,180,1) 0%, rgba(255,255,255,0.22872899159663862) 20%)',

//			background: 'rgb(0,0,0)',
			background: 'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(180,180,180,1) 25px, rgba(255,255,255,0.22872899159663862) 150px)',

			paddingTop: '30px',
		}}>
			<div className="container">
				<div className="column">
					<div className="row">
						<Tile
							imageMobile={imageMobile_bluestone_tumbled}
							imageDesktop={imageDesktop_bluestone_tumbled}
							imageAlt="Tumbled Bluestone"
						>Tumbled Bluestone</Tile>
						<Tile
							imageMobile={imageMobile_granite_american}
							imageDesktop={imageDesktop_granite_american}
							imageAlt="American Granite"
						>American Granite</Tile>
						<Tile
							imageMobile={imageMobile_granite_assorted}
							imageDesktop={imageDesktop_granite_assorted}
							imageAlt="Assorted Granite"
						>Assorted Granite</Tile>
						<Tile
							imageMobile={imageMobile_granite_saratoga}
							imageDesktop={imageDesktop_granite_saratoga}
							imageAlt="Saratoga Granite"
						>Saratoga Granite</Tile>
						<Tile
							imageMobile={imageMobile_granite_summit}
							imageDesktop={imageDesktop_granite_summit}
							imageAlt="Summit Granite"
						>Summit Granite</Tile>
						<Tile
							imageMobile={imageMobile_granite_van_tassell}
							imageDesktop={imageDesktop_granite_van_tassell}
							imageAlt="Van Tassell Granite"
						>Van Tassell Granite</Tile>
						<Tile
							imageMobile={imageMobile_new_england_fieldstone_01}
							imageDesktop={imageDesktop_new_england_fieldstone_01}
							imageAlt="New England Fieldstone"
						>New England Fieldstone</Tile>
						<Tile
							imageMobile={imageMobile_new_england_fieldstone_02}
							imageDesktop={imageDesktop_new_england_fieldstone_02}
							imageAlt="New England Fieldstone"
						>New England Fieldstone</Tile>
						<Tile
							imageMobile={imageMobile_new_england_flats}
							imageDesktop={imageDesktop_new_england_flats}
							imageAlt="New England Flats"
						>New England Flats</Tile>
						<Tile
							imageMobile={imageMobile_pennsylvania}
							imageDesktop={imageDesktop_pennsylvania}
							imageAlt="Pennsylvania"
						>Pennsylvania</Tile>
						<Tile
							imageMobile={imageMobile_quartzite_south_bay}
							imageDesktop={imageDesktop_quartzite_south_bay}
							imageAlt="South Bay Quartzite"
						>South Bay Quartzite</Tile>
						<Tile
							imageMobile={imageMobile_ticonderoga}
							imageDesktop={imageDesktop_ticonderoga}
							imageAlt="Ticonderoga"
						>Ticonderoga</Tile>
					</div>
				</div>
			</div>
		</div>
	);
}

function Tile({children,imageMobile,imageDesktop,imageAlt}) {

	const sources = [
		imageMobile.childImageSharp.fluid,
		{
		...imageDesktop.childImageSharp.fluid,
			media: `(min-width: 768px)`,
		},
	];

	return (
		<div className="col-lg-4 col-md-6 col-sm-12" style={{
			marginBottom: '30px',
		}}>
			<Reveal>
				<Img fluid={sources} alt={imageAlt} style={{
					borderRadius: '20px',
					boxShadow: '2px 2px 3px 0px #222',
					height: '200px',
					width: '100%',
				}} />
				<div style={{textAlign:'center',}}>{children}</div>
			</Reveal>
		</div>
	);
}